<template>
    <Card>
        <div slot="title">{{ title }}服务信息</div>
        <div class="form_item">
            <div class="form_label must_input">服务名称</div>
            <Input v-model.trim="formData.title" class="width_300" maxlength="10" show-word-limit></Input>
        </div>
        <div class="form_item">
            <div class="form_label align_self_start must_input">服务介绍</div>
            <editor v-model.trim="formData.note"></editor>
        </div>
        <div class="form_item">
            <div class="form_label align_self_start must_input">icon</div>
            <div>
                <uploadImage :width="100" :height="100" v-model="formData.pic"></uploadImage>
                <div class="tip_txt">图片尺寸：84x84</div>
            </div>
        </div>
        <div class="form_item">
            <div class="form_label align_self_start must_input">轮播图</div>
            <div>
                <uploadImageMultiple :width="100" :height="100" v-model="pic_note" :len="3"></uploadImageMultiple>
                <div class="tip_txt">图片尺寸：750x455</div>
            </div>
        </div>
        <div class="form_item">
            <div class="form_label">状态</div>
            <RadioGroup v-model="formData.status" type="button" button-style="solid">
                <Radio label="1">开启</Radio>
                <Radio label="0">关闭</Radio>
            </RadioGroup>
        </div>
        <div class="mt_50 flex align_center justify_center form_item">
            <i-button type="primary" ghost @click="onCloseEdit">取消</i-button>
            <i-button class="ml_50" type="success" :loading="ajaxLoading" @click="onConfirmEdit">确认</i-button>
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </Card>
</template>
<script>
import editor from '@/components/editor';
import uploadImage from '@/components/uploadImage';
import uploadImageMultiple from '@/components/uploadImageMultiple';
import { reqSaveServe, reqServeView } from '@/lib/request/auth';

export default {
    name: 'serviceManagerAdd',
    components: {
        editor,
        uploadImage,
        uploadImageMultiple,
    },
    data() {
        return {
            isEdit: false,
            title: '添加',
            formData: {
                id: null,
                title: null,
                note: null,
                pic: null,
                status: '0',
            },
            pic_note: [],
        };
    },
    mounted() {
        this.routeIdInject();
        this.title = this.isEdit ? '编辑' : '添加';
        this.getDetail();
    },
    methods: {
        //获取服务详情
        getDetail() {
            if (this.isEdit) {
                this.showAjaxLoading();
                reqServeView({ id: this.formData.id })
                    .then(res => {
                        this.dataInjectFormData(res);
                        let note = this.formData.note || '';
                        note = note.replace(/&lt;/g, '<');
                        note = note.replace(/&gt;/g, '>');
                        note = note.replace(/&quot;/g, "'");
                        note = note.replace(/\<img/g, '<img style="max-width:100%;"');
                        this.formData.note = note;
                        let pic_note = res?.data?.pic_note || null;
                        if (!!pic_note) {
                            this.pic_note = pic_note.split(',');
                        }
                    })
                    .finally(() => {
                        this.hideAjaxLoading();
                    });
            }
        },
        //取消
        onCloseEdit() {
            this.$router.back();
        },
        //确认
        onConfirmEdit() {
            this.showAjaxLoading();
            let params = window.structuredClone(this.formData);

            let { title, note, pic } = params || {};
            let vs = [
                { value: title, tip: '请输入服务名称' },
                { value: note, tip: '请输入服务介绍' },
                { value: pic, tip: '请选择图片' },
            ];

            hm.validateForm(vs)
                .then(() => {
                    console.log('onConfirmEdit', params);
                    let pic_note = this.pic_note || [];
                    if (pic_note.length <= 0) {
                        this.fadeWarning('至少选择一张轮播图');
                        this.hideAjaxLoading();
                        return;
                    }
                    params.pic_note = pic_note.join(',');
                    reqSaveServe(params)
                        .then(res => {
                            this.fadeAlert(res.errmsg);
                            this.goUrl('/serviceList');
                        })
                        .catch(() => {
                            this.hideAjaxLoading();
                        });
                })
                .catch(msg => {
                    this.fadeWarning(msg);
                    this.hideAjaxLoading();
                });
        },
    },
};
</script>
